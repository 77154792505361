<template>
  <div class="card-square">
    <div class="card-square__preview" />
    <atomic-image v-if="src" class="card-square__img" :src="src" />
    <div class="card-square__overlay" />
  </div>
</template>

<script setup lang="ts">
  import type { IGame } from '~/types';

  const props = defineProps<{
    gameInfo?: IGame;
  }>();

  const { getImageUrl } = useProjectMethods();
  const src = computed(() => {
    if (props.gameInfo?.images?.hasOwnProperty('200x200')) {
      return getImageUrl(props.gameInfo.customImages, props.gameInfo.images, 'square');
    }

    return '';
  });
</script>

<style src="~/assets/styles/components/card/square.scss" lang="scss" />

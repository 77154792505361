<template>
  <div
    class="card-free-spin"
    :class="{
      passive: props.freeSpinInfo.status === 4 && !props.hideButton,
      'card-free-spin_home-page': isHomePage,
    }"
  >
    <div class="card-free-spin__container" :class="{ 'card-free-spin__container_home-page': isHomePage }">
      <div v-if="isHomePage" class="card-free-spin__cta-container">
        <atomic-icon id="free-spin-gift" filled />

        <div class="card-free-spin__cta" v-html="callToActionTitle" />
      </div>

      <div class="card-free-spin__title" v-html="cardTitle" />

      <div v-if="!!expiredTimer" class="card-free-spin__timer">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.expiredTimerLabel') }}:

        <bonuses-timer :expired-at="expiredTimer as string" size="sm" />
      </div>

      <card-square v-if="gameInfo" :gameInfo="gameInfo" />
    </div>

    <div
      v-if="!props.hideButton"
      class="card-free-spin__actions"
      :class="{ 'card-free-spin__actions_home-page': isHomePage }"
    >
      <div v-if="props.freeSpinInfo.status === 4" class="card-free-spin__available">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.availableTimerLabel') }}

        <bonuses-timer :expired-at="availableTimer as string" size="sm" />
      </div>

      <button-base
        v-else
        class="card-free-spin__actions-play"
        type="secondary-1"
        size="sm"
        :isDisabled="processingBonuses.includes(props.freeSpinInfo.id)"
        @click="clickAction"
      >
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.freeSpin.playButton') }}
      </button-base>

      <button-base
        v-if="!isHomePage"
        class="card-free-spin__actions-info"
        type="secondary-2"
        size="sm"
        @click="openInfo"
      >
        <atomic-icon id="info" />
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IGame, IPlayerFreeSpin } from '~/core/types';

  const props = defineProps<{
    freeSpinInfo: IPlayerFreeSpin;
    hideButton?: boolean;
    gameInfo?: IGame;
    isHomePage?: boolean;
  }>();

  const bonusStore = useBonusStore();
  const { processingBonuses } = storeToRefs(bonusStore);
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent, alertsData, defaultLocaleAlertsData } =
    useGlobalStore();
  const { showAlert } = useLayoutStore();
  const { getContent, localizePath } = useProjectMethods();
  const { getGamesInfo } = useCoreGamesApi();
  const { data: gameInfo } = useLazyAsyncData(props.freeSpinInfo.gameId, () => {
    if (props.gameInfo) return Promise.resolve(props.gameInfo);
    return getGamesInfo(props.freeSpinInfo.gameId);
  });

  const label = computed(() => {
    return getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.freeSpin.label') || '';
  });

  const formatFreeSpinValue = (count: number, label: string) => `<span>${count} ${label}</span>`;

  const cardTitle = computed(() => {
    if (!gameInfo.value) return '';

    const titleContent =
      getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.freeSpin.title') || '';

    const titleWithVars = titleContent
      .replace('{value}', formatFreeSpinValue(props.freeSpinInfo.count, label.value))
      .replace('{game}', gameInfo.value.name);

    return titleWithVars;
  });

  const callToActionTitle = computed(() => {
    const titleContent =
      getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.freeSpin.callToActionTitle') ||
      '';

    const titleWithVars = titleContent.replace('{value}', formatFreeSpinValue(props.freeSpinInfo.count, label.value));

    return titleWithVars;
  });

  const availableTimer = computed(() => {
    if (props.freeSpinInfo.status === 4 && props.freeSpinInfo.issuedAt) return props.freeSpinInfo.issuedAt;
    return undefined;
  });

  const expiredTimer = computed(() => {
    if (props.freeSpinInfo.status === 1 && props.freeSpinInfo.activationExpiredAt)
      return props.freeSpinInfo.activationExpiredAt;
    if (props.freeSpinInfo.status === 2 && props.freeSpinInfo.expiredAt) return props.freeSpinInfo.expiredAt;
    return undefined;
  });

  const { openModal } = useModalStore();
  const openInfo = async (): Promise<void> => {
    await openModal('free-spin-detail', {
      props: { freeSpinInfo: props.freeSpinInfo, gameInfo: gameInfo.value },
    });
  };

  const router = useRouter();
  const { activatePlayerFreeSpin } = useCoreBonusApi();
  const clickAction = async (): Promise<void> => {
    if (props.freeSpinInfo.status === 1) {
      if (processingBonuses.value.includes(props.freeSpinInfo.id)) return;
      processingBonuses.value.push(props.freeSpinInfo.id);

      try {
        await activatePlayerFreeSpin(props.freeSpinInfo.id);
        processingBonuses.value = processingBonuses.value.filter(id => id !== props.freeSpinInfo.id);
        await router.push(localizePath(`/games/${gameInfo.value?.identity}?real=true`));
      } catch {
        processingBonuses.value = processingBonuses.value.filter(id => id !== props.freeSpinInfo.id);
        showAlert(alertsData?.global?.somethingWrong || defaultLocaleAlertsData?.global?.somethingWrong);
      }
    } else {
      await router.push(localizePath(`/games/${gameInfo.value?.identity}?real=true`));
    }
  };
</script>

<style src="~/assets/styles/components/card/free-spin.scss" lang="scss" />
